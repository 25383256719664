import { Button } from 'components/ui/button'
import { defaultIconProps } from 'config/constants.config'
import { RefreshCw } from 'lucide-react'
import { memo, useCallback, useState } from 'react'
import { cn } from 'utils/cn'

interface RefreshButtonProps extends React.ComponentProps<typeof Button> {}

export const RefreshButton: React.FC<RefreshButtonProps> = memo(({ onClick, ...props }) => {
    const [isSpinning, setSpinning] = useState(false)
    const [tmout, setTmout] = useState<NodeJS.Timeout | null>(null)

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setSpinning(true)

            if (tmout) {
                clearTimeout(tmout)
            }

            const newTimeout = setTimeout(() => {
                setSpinning(false)
            }, 1000)
            setTmout(newTimeout)

            onClick?.(e)
        },
        [onClick, tmout],
    )

    return (
        <Button variant="outline" size="icon" onClick={handleClick} {...props}>
            <RefreshCw
                {...defaultIconProps}
                className={cn(
                    'animate-spin transition-all duration-1000 ease-in-out',
                    isSpinning && 'running',
                    !isSpinning && 'paused',
                )}
            />
        </Button>
    )
})
