import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { PropsWithChildren, memo } from 'react'
import { cn } from 'utils/cn'

interface AppLogsCardProps extends React.ComponentProps<typeof Card> {
    title?: string
    description?: string
}
export const AppLogsCard: React.FC<PropsWithChildren<AppLogsCardProps>> = memo(
    ({ title, description, className, children, ...props }) => {
        return (
            <Card {...props} className={cn('whitespace-pre-wrap', className)}>
                {(title || description) && (
                    <CardHeader>
                        {title && <CardTitle>{title}</CardTitle>}
                        {description && <CardDescription>{description}</CardDescription>}
                    </CardHeader>
                )}
                <CardContent className="leading-relaxed">{children}</CardContent>
            </Card>
        )
    },
)
