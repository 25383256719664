import { ColumnDef } from '@tanstack/react-table'
import { TableDateCell } from 'components/table-date-cell'
import { TableUrlCell } from 'components/table-url-cell'
import { DataTableColumnHeader } from 'components/ui/data-table-column-header'
import { DashboardIntervalCell } from 'pages/dashboard/cells/dashboard-interval-cell'
import { DashboardRowActionsCell } from 'pages/dashboard/cells/dashboard-row-actions-cell'
import { DashboardStatusCell } from 'pages/dashboard/cells/dashboard-status-cell'
import { ProjectSchemaType } from 'types/schemas/project.schema'

export const dashboardColumns: ColumnDef<ProjectSchemaType>[] = [
    {
        accessorKey: 'id',
        header: ({ column }) => <DataTableColumnHeader column={column} title="ID" />,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'url',
        header: ({ column }) => <DataTableColumnHeader column={column} title="URL" />,
        cell: ({ row }) => <TableUrlCell url={row.getValue('url')} />,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'email',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'status',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
        cell: ({ row }) => (
            <DashboardStatusCell id={row.getValue('id')} status={row.getValue('status')} className="w-full max-w-[150px]" />
        ),
        sortingFn: 'text',
        filterFn: 'weakEquals',
    },
    {
        accessorKey: 'statusCode',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Code" />,
        sortingFn: 'text',
    },
    {
        accessorKey: 'ping',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Ping" />,
        sortingFn: 'text',
    },
    {
        accessorKey: 'interval',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Interval" />,
        cell: ({ row }) => <DashboardIntervalCell interval={row.getValue('interval')} />,
        sortingFn: 'text',
    },
    {
        accessorKey: 'lastCheck',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Last Update" />,
        cell: ({ row }) => <TableDateCell date={row.getValue('lastCheck')} />,
        sortingFn: 'datetime',
    },
    {
        accessorKey: 'actions',
        header: 'Actions',
        cell: ({ row }) => <DashboardRowActionsCell id={row.getValue('id')} name={row.getValue('name')} />,
    },
]
