import { ColumnDef } from '@tanstack/react-table'
import { TableDateCell } from 'components/table-date-cell'
import { TableUrlCell } from 'components/table-url-cell'
import { DataTableColumnHeader } from 'components/ui/data-table-column-header'
import { ProjectLogType } from 'types/project-log.type'

export const projectLogsColumns: ColumnDef<ProjectLogType>[] = [
    {
        accessorKey: 'id',
        header: ({ column }) => <DataTableColumnHeader column={column} title="ID" />,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'date',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Date" />,
        cell: ({ row }) => <TableDateCell date={row.getValue('date')} />,
        sortingFn: 'datetime',
    },
    {
        accessorKey: 'error',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Error" />,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'statusCode',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Status Code" />,
        sortingFn: 'text',
    },
    {
        accessorKey: 'projectId',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Project ID" />,
        sortingFn: 'text',
    },
    {
        accessorKey: 'projectName',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Project Name" />,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'projectUrl',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Project URL" />,
        cell: ({ row }) => <TableUrlCell url={row.getValue('projectUrl')} />,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'projectEmail',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Project Email" />,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
]
