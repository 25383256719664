import { AuthProvider } from 'components/hoc/auth-provider'
import { ReduxProvider } from 'components/hoc/redux-provider'
import { PropsWithChildren, memo } from 'react'

interface ProvidersProps {}
export const Providers: React.FC<PropsWithChildren<ProvidersProps>> = memo(({ children }) => {
    return (
        <AuthProvider>
            <ReduxProvider>{children}</ReduxProvider>
        </AuthProvider>
    )
})
Providers.displayName = 'Providers'
