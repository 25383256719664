import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { revalidateSeconds, rtkBaseQuery } from 'config/rtkQuery.config'
import { urlConfig } from 'config/url.config'
import { AppLogType } from 'types/app-log.type'

export const appLogApi = createApi({
    reducerPath: 'appLogApi',
    tagTypes: ['AppLog'],
    keepUnusedDataFor: revalidateSeconds,
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        getAuthLAppLogs: builder.query<AppLogType, void>({
            query: () => ({
                url: `${urlConfig.api.appLogs}/auth`,
                method: 'GET',
                responseHandler: response => response.text(),
            }),
            providesTags: ['AppLog'],
        }),
        getInfoLAppLogs: builder.query<AppLogType, void>({
            query: () => ({
                url: `${urlConfig.api.appLogs}/info`,
                method: 'GET',
                responseHandler: response => response.text(),
            }),
            providesTags: ['AppLog'],
        }),
        getDebugAppLogs: builder.query<AppLogType, void>({
            query: () => ({
                url: `${urlConfig.api.appLogs}/debug`,
                method: 'GET',
                responseHandler: response => response.text(),
            }),
            providesTags: ['AppLog'],
        }),
        getWarnLAppLogs: builder.query<AppLogType, void>({
            query: () => ({
                url: `${urlConfig.api.appLogs}/warn`,
                method: 'GET',
                responseHandler: response => response.text(),
            }),
            providesTags: ['AppLog'],
        }),
        getErrorAppLogs: builder.query<AppLogType, void>({
            query: () => ({
                url: `${urlConfig.api.appLogs}/error`,
                method: 'GET',
                responseHandler: response => response.text(),
            }),
            providesTags: ['AppLog'],
        }),
        deleteAppLogs: builder.mutation<void, void>({
            query: () => ({
                url: `${urlConfig.api.appLogs}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AppLog'],
        }),
    }),
})

export const {
    useGetAuthLAppLogsQuery,
    useGetInfoLAppLogsQuery,
    useGetDebugAppLogsQuery,
    useGetWarnLAppLogsQuery,
    useGetErrorAppLogsQuery,
    useDeleteAppLogsMutation,
} = appLogApi
