import { FullPageLoader } from 'components/full-page-loader'
import { Heading } from 'components/heading'
import { RefreshButton } from 'components/refresh-button'
import { DataTable } from 'components/ui/data-table'
import { DataTableFacetedFilter } from 'components/ui/data-table-faceted-filter'
import { DataTableInputFilter } from 'components/ui/data-table-input-filter'
import { DataTableToolbar } from 'components/ui/data-table-toolbar'
import { emptyCounter } from 'config/constants.config'
import { ENV, dotenv } from 'config/dotenv.config'
import { toastConfig } from 'config/toasts.config'
import { urlConfig } from 'config/url.config'
import { DashboardCards } from 'pages/dashboard/components/dashboard-cards'
import { DashboardCharts } from 'pages/dashboard/components/dashboard-charts'
import { dashboardColumns } from 'pages/dashboard/components/dashboard-columns'
import { memo, useCallback } from 'react'
import { useGetCounterQuery } from 'redux/api/counter-api'
import { useGetProjectsQuery } from 'redux/api/project-api'
import { ProjectStatus } from 'types/schemas/project.schema'

interface MainPageProps {}
export const MainPage: React.FC<MainPageProps> = memo(() => {
    const { data: projectsData, isLoading: projectsIsLoading, refetch: projectsRefetch } = useGetProjectsQuery()
    const { data: counterData, isLoading: counterIsLoading, refetch: counterRefetch } = useGetCounterQuery()

    const onRefetch = useCallback(async () => {
        try {
            const res = await fetch(`${dotenv.get(ENV.API_URL)}${urlConfig.api.revalidate}`, {
                method: 'POST',
            })
            if (!res.ok) {
                throw new Error('Failed to refetch')
            }
            projectsRefetch()
            counterRefetch()
            toastConfig.refetch.success()
        } catch (error) {
            console.error(error)
            toastConfig.refetch.error()
        }
    }, [counterRefetch, projectsRefetch])

    if (projectsIsLoading || counterIsLoading) {
        return <FullPageLoader />
    }

    return (
        <div className="animate-in fade-in-0 container min-h-screen space-y-5 pt-20 transition-all duration-300">
            <div className="flex w-full items-center justify-between">
                <Heading title="Dashboard" description="Manage your projects" />
                <RefreshButton onClick={onRefetch} />
            </div>

            <DashboardCards data={projectsData ?? []} className="flex gap-5" />

            <DataTable
                columns={dashboardColumns}
                data={projectsData ?? []}
                pagination
                initialSorting={{
                    column: 'name',
                    direction: 'asc',
                }}
                initialHidden={['id']}
            >
                <DataTableToolbar>
                    <DataTableInputFilter column="name" label="Name" />
                    <DataTableInputFilter column="url" label="URL" />
                    <DataTableFacetedFilter
                        column="status"
                        options={Object.values(ProjectStatus).map(status => ({ data: status }))}
                    />
                </DataTableToolbar>
            </DataTable>

            <DashboardCharts data={counterData ?? emptyCounter} className="grid w-full grid-cols-2 gap-5" />

            <div className="h-5" />
        </div>
    )
})
MainPage.displayName = 'MainPage'
