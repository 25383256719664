import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { revalidateSeconds, rtkBaseQuery } from 'config/rtkQuery.config'
import { urlConfig } from 'config/url.config'
import { ProjectLogType } from 'types/project-log.type'

export const projectLogsApi = createApi({
    reducerPath: 'projectLogsApi',
    tagTypes: ['ProjectLog'],
    keepUnusedDataFor: revalidateSeconds,
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        getProjectLogs: builder.query<ProjectLogType[], void>({
            query: () => urlConfig.api.projectLogs,
            providesTags: ['ProjectLog'],
        }),
    }),
})

export const { useGetProjectLogsQuery } = projectLogsApi
