import { LoadingIcon } from 'components/loading-icon'
import { Button } from 'components/ui/button'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { memo } from 'react'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'
import { LoginSchemaType } from 'types/schemas/login.schema'

interface LoginEmailFormProps {
    form: UseFormReturn<LoginSchemaType>
    onSubmit: SubmitHandler<LoginSchemaType>
    isButtonDisabled: boolean
}
export const LoginEmailForm: React.FC<LoginEmailFormProps> = memo(({ form, onSubmit, isButtonDisabled }) => {
    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Email</FormLabel>
                            <FormControl>
                                <Input placeholder="someemail@email.com" {...field} required />
                            </FormControl>
                            <FormDescription className="text-xs">
                                After entering your email, you`ll have to confirm it by clicking on the link in the letter
                            </FormDescription>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <Button type="submit" variant="default" disabled={isButtonDisabled}>
                    <LoadingIcon loading={isButtonDisabled} className="mr-2" />
                    Submit
                </Button>
            </form>
        </Form>
    )
})
LoginEmailForm.displayName = 'LoginEmailForm'
