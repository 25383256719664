import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const localKey = 'command-tip-showed'

interface CommandSliceState {
    isOpen: boolean
    tipAlreadyShowed: boolean
}

const initialState: CommandSliceState = {
    isOpen: false,
    tipAlreadyShowed: JSON.parse(localStorage.getItem(localKey) ?? 'false') as boolean,
}

export const commandSlice = createSlice({
    name: 'command',
    initialState,
    reducers: {
        setIsCommandOpenAction: (state, action: PayloadAction<boolean | undefined>) => {
            state.isOpen = action.payload ?? !state.isOpen
        },
        setTipAlreadyShowedAction: (state, action: PayloadAction<boolean>) => {
            state.tipAlreadyShowed = action.payload
            localStorage.setItem(localKey, JSON.stringify(action.payload))
        },
    },
})

export const { setIsCommandOpenAction, setTipAlreadyShowedAction } = commandSlice.actions
