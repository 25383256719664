import { Moon, Sparkle, Sun } from 'lucide-react'
import { CounterType } from 'types/counter.type'
import { ThemeType } from 'types/theme.type'

export const defaultIconProps = {
    strokeWidth: 1.5,
}

export const themesIcons = {
    light: <Sun {...defaultIconProps} />,
    dark: <Moon {...defaultIconProps} />,
    system: <Sparkle {...defaultIconProps} />,
}

export const themes: Array<{
    name: ThemeType
    icon: JSX.Element
}> = [
    {
        name: 'light',
        icon: themesIcons.light,
    },
    {
        name: 'dark',
        icon: themesIcons.dark,
    },
    {
        name: 'system',
        icon: themesIcons.system,
    },
]

export const isMacLike = /[mac|apple]/gi.test(navigator.userAgent)

export const commandMenuShortcut = `${isMacLike ? '⌘' : 'Ctrl'} K`

export const MAX_FUNCTIONS_INVOCATIONS = 2_000_000
export const MAX_FIRESTORE_READS = 50_000
export const MAX_FIRESTORE_WRITES = 20_000
export const MAX_FIRESTORE_DELETES = 20_000

export const emptyCounter: CounterType = {
    id: '',
    functions: {
        invocations: 0,
    },
    firestore: {
        reads: 0,
        writes: 0,
        deletes: 0,
    },
}
