import { CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from 'components/ui/command'
import { defaultIconProps, themes } from 'config/constants.config'
import { toastConfig } from 'config/toasts.config'
import { urlConfig } from 'config/url.config.ts'
import { Braces, FolderPlus, LayoutDashboard, LogIn, LogOut, Parentheses } from 'lucide-react'
import { CreateProjectSheet } from 'pages/create-project/create-project-sheet'
import { memo, useCallback, useEffect, useMemo } from 'react'
import { useIsAuthenticated, useSignOut } from 'react-auth-kit'
import { isMobile } from 'react-device-detect'
import { Link, useNavigate } from 'react-router-dom'
import { useGetProjectsQuery } from 'redux/api/project-api'
import { setIsCommandOpenAction, setTipAlreadyShowedAction } from 'redux/slices/command.slice'
import { setIsCreateProjectOpenAction } from 'redux/slices/create-project.slice'
import { setThemeAction } from 'redux/slices/theme.slice'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { PropsWithoutChildren } from 'types/rfc.type'
import { ThemeType } from 'types/theme.type'
import { disposableFn } from 'utils/disposable-fn'

//

const classNameWithIcon = 'flex items-center gap-3'

//

interface CommandMenuProps {}
export const CommandMenu: React.FC<PropsWithoutChildren<CommandMenuProps>> = memo(() => {
    const navigate = useNavigate()
    const isAuth = useIsAuthenticated()
    const signOut = useSignOut()
    const dispatch = useAppDispatch()
    const { isOpen, tipAlreadyShowed } = useAppSelector(state => state.command)
    const { data: projects, isLoading: projectsLoading, isError: projectsError } = useGetProjectsQuery()

    const showTipGenerator = useCallback(
        () =>
            disposableFn(() => {
                if (tipAlreadyShowed) {
                    return
                }
                if (isMobile) {
                    return
                }
                toastConfig.commandMenuTip.success()
                dispatch(setTipAlreadyShowedAction(true))
            }),
        [dispatch, tipAlreadyShowed],
    )

    const setCommandOpen = useCallback<(open?: boolean) => void>(
        open => {
            dispatch(setIsCommandOpenAction(open))
        },
        [dispatch],
    )

    const onPageOpen = useCallback(
        (to: React.ComponentProps<typeof Link>['to']) => {
            return () => {
                setCommandOpen(false)
                navigate(to)
            }
        },
        [navigate, setCommandOpen],
    )

    const onCreateProjectOpen = useCallback<() => void>(() => {
        dispatch(setIsCreateProjectOpenAction(true))
        setCommandOpen(false)
    }, [dispatch, setCommandOpen])

    const onThemeChange = useCallback(
        (newTheme: ThemeType) => {
            return () => {
                dispatch(setThemeAction(newTheme))
                setCommandOpen(false)
            }
        },
        [dispatch, setCommandOpen],
    )

    const onLogOut = useCallback<() => void>(() => {
        setCommandOpen(false)
        signOut()
    }, [setCommandOpen, signOut])

    const onLogIn = useCallback<() => void>(() => {
        setCommandOpen(false)
        navigate('/login')
    }, [navigate, setCommandOpen])

    const onTipShow = useMemo<ReturnType<typeof showTipGenerator>>(() => showTipGenerator(), [showTipGenerator])

    useEffect(() => {
        const toggle = (e: KeyboardEvent) => {
            if (!(e.key === 'k' && e.metaKey)) {
                return
            }
            e.preventDefault()
            setCommandOpen()
            dispatch(setIsCreateProjectOpenAction(false))
        }
        document.addEventListener('keydown', toggle)
        return () => {
            document.removeEventListener('keydown', toggle)
        }
    }, [dispatch, setCommandOpen])

    useEffect(() => {
        setTimeout(() => {
            onTipShow()
        }, 3000)
    }, [onTipShow])

    return (
        <CommandDialog open={isOpen} onOpenChange={setCommandOpen}>
            <CommandInput placeholder="Type a command or search..." />
            <CommandList className="pb-1">
                <CommandEmpty>No results found</CommandEmpty>
                {/* auth only */}
                {isAuth() && (
                    <CommandGroup heading="Pages">
                        <CommandItem onSelect={onPageOpen(urlConfig.pages.dashboard)} className={classNameWithIcon}>
                            <LayoutDashboard {...defaultIconProps} />
                            <span>Dashboard</span>
                        </CommandItem>
                        <CreateProjectSheet className="w-full">
                            <CommandItem onSelect={onCreateProjectOpen} className={classNameWithIcon}>
                                <FolderPlus {...defaultIconProps} />
                                <span>Create Project</span>
                            </CommandItem>
                        </CreateProjectSheet>
                        <CommandItem onSelect={onPageOpen(urlConfig.pages.projectsLogs)} className={classNameWithIcon}>
                            <Parentheses {...defaultIconProps} />
                            <span>Projects Logs</span>
                        </CommandItem>
                        <CommandItem onSelect={onPageOpen(urlConfig.pages.appLogs)} className={classNameWithIcon}>
                            <Braces {...defaultIconProps} />
                            <span>App Logs</span>
                        </CommandItem>
                    </CommandGroup>
                )}
                {/* auth / non auth */}
                <CommandGroup heading="User">
                    {isAuth() ? (
                        <CommandItem onSelect={onLogOut} className={classNameWithIcon}>
                            <LogOut {...defaultIconProps} />
                            <span>Log out</span>
                        </CommandItem>
                    ) : (
                        <CommandItem onSelect={onLogIn} className={classNameWithIcon}>
                            <LogIn {...defaultIconProps} />
                            <span>Log In</span>
                        </CommandItem>
                    )}
                </CommandGroup>
                {/* all */}
                <CommandGroup heading="Themes">
                    {themes.map(theme => (
                        <CommandItem key={theme.name} onSelect={onThemeChange(theme.name)} className={classNameWithIcon}>
                            {theme.icon}
                            <span>
                                Theme: <span className="capitalize">{theme.name}</span>
                            </span>
                        </CommandItem>
                    ))}
                </CommandGroup>
                {/* auth only */}
                {/* {isAuth() && (
                    <CommandGroup heading="Projects">
                        {projectsLoading && <CommandItem>Loading projects...</CommandItem>}
                        {projectsError && <CommandItem>Error loading projects</CommandItem>}
                        {!projectsLoading &&
                            !projectsError &&
                            projects &&
                            projects
                                .filter(project => project.status !== ProjectStatus.PENDING)
                                .map(project => (
                                    <CommandItem
                                        key={project.id}
                                        onSelect={onPageOpen(`/details/${project.id}`)}
                                        className={classNameWithIcon}
                                    >
                                        <BoxSelect {...defaultIconProps} />
                                        <span>{project.name}</span>
                                    </CommandItem>
                                ))}
                    </CommandGroup>
                )} */}
            </CommandList>
        </CommandDialog>
    )
})
CommandMenu.displayName = 'CommandMenu'
