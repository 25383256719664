import { PropsWithChildren, memo } from 'react'
import { AuthProvider as AuthKitProvider } from 'react-auth-kit'

interface AuthProviderProps {}
export const AuthProvider: React.FC<PropsWithChildren<AuthProviderProps>> = memo(({ children }) => {
    return (
        <AuthKitProvider
            authType="cookie"
            authName="_auth"
            cookieDomain={window.location.hostname}
            cookieSecure={window.location.protocol === 'https:'}
        >
            {children}
        </AuthKitProvider>
    )
})
AuthProvider.displayName = 'AuthProvider'
