import { memo } from 'react'
import { ProjectSchemaType } from 'types/schemas/project.schema'

interface DashboardIntervalCellProps extends React.ComponentProps<'span'> {
    interval: ProjectSchemaType['interval']
}
export const DashboardIntervalCell: React.FC<DashboardIntervalCellProps> = memo(({ interval, ...props }) => {
    return <span {...props}>{interval}m</span>
})
DashboardIntervalCell.displayName = 'DashboardIntervalCell'
