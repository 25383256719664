import type { ToastActionElement, ToastProps } from 'components/ui/toast'
import { useEffect, useState } from 'react'

type ToasterToast = ToastProps & {
    id: string
    title?: React.ReactNode
    description?: React.ReactNode
    action?: ToastActionElement
}

type Toast = Omit<ToasterToast, 'id'>

enum ActionType {
    ADD_TOAST,
    UPDATE_TOAST,
    DISMISS_TOAST,
    REMOVE_TOAST,
}

type Action =
    | {
          type: ActionType.ADD_TOAST
          toast: ToasterToast
      }
    | {
          type: ActionType.UPDATE_TOAST
          toast: Partial<ToasterToast>
      }
    | {
          type: ActionType.DISMISS_TOAST
          toastId?: ToasterToast['id']
      }
    | {
          type: ActionType.REMOVE_TOAST
          toastId?: ToasterToast['id']
      }

interface State {
    toasts: ToasterToast[]
}

//

const TOAST_LIMIT = 2
const TOAST_REMOVE_DELAY = 10_000

let idCount = 0
const listeners: Array<(state: State) => void> = []
let memoryState: State = { toasts: [] }
const toastTimeouts = new Map<string, ReturnType<typeof setTimeout>>()

//

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionType.ADD_TOAST:
            return {
                ...state,
                toasts: [action.toast, ...state.toasts].slice(0, TOAST_LIMIT),
            }

        case ActionType.UPDATE_TOAST:
            return {
                ...state,
                toasts: state.toasts.map(t => (t.id === action.toast.id ? { ...t, ...action.toast } : t)),
            }

        case ActionType.DISMISS_TOAST: {
            const { toastId } = action
            if (toastId) {
                addToRemoveQueue(toastId)
            } else {
                state.toasts.forEach(toast => {
                    addToRemoveQueue(toast.id)
                })
            }
            return {
                ...state,
                toasts: state.toasts.map(t =>
                    t.id === toastId || toastId === undefined
                        ? {
                              ...t,
                              open: false,
                          }
                        : t,
                ),
            }
        }

        case ActionType.REMOVE_TOAST:
            if (action.toastId === undefined) {
                return {
                    ...state,
                    toasts: [],
                }
            }
            return {
                ...state,
                toasts: state.toasts.filter(t => t.id !== action.toastId),
            }
    }
}

function dispatch(action: Action) {
    memoryState = reducer(memoryState, action)
    listeners.forEach(listener => {
        listener(memoryState)
    })
}

function genId() {
    idCount = (idCount + 1) % Number.MAX_VALUE
    return idCount.toString()
}

function addToRemoveQueue(toastId: string) {
    if (toastTimeouts.has(toastId)) {
        return
    }

    const timeout = setTimeout(() => {
        toastTimeouts.delete(toastId)
        dispatch({
            type: ActionType.REMOVE_TOAST,
            toastId,
        })
    }, TOAST_REMOVE_DELAY)

    toastTimeouts.set(toastId, timeout)
}

function toast({ ...props }: Toast) {
    const id = genId()

    const update = (props: ToasterToast) =>
        dispatch({
            type: ActionType.UPDATE_TOAST,
            toast: { ...props, id },
        })
    const dismiss = (duration = 0) => {
        setTimeout(() => {
            dispatch({ type: ActionType.DISMISS_TOAST, toastId: id })
        }, duration)
    }

    dispatch({
        type: ActionType.ADD_TOAST,
        toast: {
            ...props,
            id,
            open: true,
            onOpenChange: open => {
                if (!open) dismiss()
            },
        },
    })

    return {
        id,
        dismiss,
        update,
    }
}

function useToast() {
    const [state, setState] = useState<State>(memoryState)

    useEffect(() => {
        listeners.push(setState)
        return () => {
            const index = listeners.indexOf(setState)
            if (index > -1) {
                listeners.splice(index, 1)
            }
        }
    }, [state])

    return {
        ...state,
        toast,
        dismiss: (toastId?: string) => dispatch({ type: ActionType.DISMISS_TOAST, toastId }),
    }
}

export { TOAST_REMOVE_DELAY, toast, useToast }
