import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { defaultIconProps } from 'config/constants.config'
import { Activity, ArrowUpRight, Database, Loader, ZapOff } from 'lucide-react'
import { memo, useMemo } from 'react'
import { PropsWithoutChildren } from 'types/rfc.type'
import { ProjectSchemaType, ProjectStatus } from 'types/schemas/project.schema'

const iconProps = {
    ...defaultIconProps,
    className: 'text-muted-foreground',
}

interface DashboardCardsProps extends React.ComponentProps<'div'> {
    data: ProjectSchemaType[]
}
export const DashboardCards: React.FC<PropsWithoutChildren<DashboardCardsProps>> = memo(({ data, ...props }) => {
    const total = useMemo(() => data.length, [data.length])
    const ok = useMemo(() => data.filter(item => item.status === ProjectStatus.OK).length, [data])
    const down = useMemo(() => data.filter(item => item.status === ProjectStatus.DOWN).length, [data])
    const redirect = useMemo(() => data.filter(item => item.status === ProjectStatus.REDIRECT).length, [data])
    const pending = useMemo(() => data.filter(item => item.status === ProjectStatus.PENDING).length, [data])

    const projectCards = useMemo<
        Array<{
            title: string
            value: number
            icon: React.ReactNode
        }>
    >(
        () => [
            {
                title: 'Total Projects',
                value: total,
                icon: <Database {...iconProps} />,
            },
            {
                title: 'Status: Ok',
                value: ok,
                icon: <Activity {...iconProps} />,
            },
            {
                title: 'Status: Down',
                value: down,
                icon: <ZapOff {...iconProps} />,
            },
            {
                title: 'Status: Redirect',
                value: redirect,
                icon: <ArrowUpRight {...iconProps} />,
            },
            {
                title: 'Status: Pending',
                value: pending,
                icon: <Loader {...iconProps} />,
            },
        ],
        [down, ok, pending, redirect, total],
    )

    return (
        <div {...props}>
            {projectCards.map(card => (
                <Card key={card.title} className="flex-1">
                    <CardHeader>
                        <CardTitle className="text-sm">{card.title}</CardTitle>
                    </CardHeader>
                    <CardContent className="flex items-center justify-between">
                        <h1 className="text-3xl">{card.value}</h1>
                        {card.icon}
                    </CardContent>
                </Card>
            ))}
        </div>
    )
})
DashboardCards.displayName = 'DashboardCards'
