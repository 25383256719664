import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.extend(localeData)
dayjs.extend(weekOfYear)

// const userLocale = dayjs.localeData()

type FormatToLocaleDate = (date?: string) => string
export const formatLocalDate: FormatToLocaleDate = date => dayjs(date).format('DD MMMM YYYY, HH:mm:ss')

type GetTimeDifference = (date: string, formatFn?: FormatToLocaleDate) => string
export const getTimeDifference: GetTimeDifference = (date, formatFn) => {
    const now = dayjs()
    const targetDate = dayjs(date)
    const diffMinutes = now.diff(targetDate, 'minutes')
    const diffHours = now.diff(targetDate, 'hours')
    if (diffMinutes < 1) {
        return 'Just now'
    }
    if (diffMinutes < 60) {
        return `${diffMinutes} minutes ago`
    }
    if (diffHours < 24) {
        return `${diffHours} hours ago`
    }
    if (formatFn) {
        return formatFn(date)
    }
    return formatLocalDate(date)
}
