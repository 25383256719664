import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { PropsWithChildren, memo } from 'react'

interface ConfirmDialogProps extends React.ComponentProps<typeof AlertDialogTrigger> {
    title?: string
    description?: string
    onClick?: () => void
}
export const ConfirmDialog: React.FC<PropsWithChildren<ConfirmDialogProps>> = memo(
    ({ title, description, onClick, className, children, ...props }) => {
        return (
            <AlertDialog>
                <AlertDialogTrigger {...props}>{children}</AlertDialogTrigger>
                <AlertDialogContent>
                    {title && description && (
                        <AlertDialogHeader>
                            {title && <AlertDialogTitle>{title}</AlertDialogTitle>}
                            {description && <AlertDialogDescription>{description}</AlertDialogDescription>}
                        </AlertDialogHeader>
                    )}
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        {onClick && <AlertDialogAction onClick={onClick}>Continue</AlertDialogAction>}
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        )
    },
)
ConfirmDialog.displayName = 'ConfirmDialog'
