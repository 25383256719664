import { OnSignInWithGoogle } from 'components/sign-in-with-google'
import { ENV, dotenv } from 'config/dotenv.config'
import { toastConfig } from 'config/toasts.config'
import { urlConfig } from 'config/url.config'
import { useCallback, useState } from 'react'
import { useSignIn } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom'

type AuthState = Parameters<OnSignInWithGoogle>[0]

export const useLogin = () => {
    const signIn = useSignIn()
    const navigate = useNavigate()
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)

    const login = useCallback<(params: { authState: AuthState; callbackUrl: string }) => void>(
        async ({ authState, callbackUrl }) => {
            try {
                if (!authState.email || !authState.name) {
                    throw new Error('Invalid auth state')
                }

                setIsButtonDisabled(true)

                const resPromise = await fetch(`${dotenv.get(ENV.API_URL)}${urlConfig.api.login}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: authState.email,
                    }),
                })
                if (!resPromise.ok) {
                    throw new Error('Failed to login')
                }

                const res = (await resPromise.json()) as {
                    message: string
                    token: string
                }
                if (!res.token) {
                    throw new Error('Failed to login')
                }

                signIn({
                    token: res.token,
                    expiresIn: 24 * 60,
                    tokenType: 'Bearer',
                    authState: {
                        ...authState,
                    },
                })
                toastConfig.login.success(authState?.name)
                navigate(callbackUrl)
            } catch (error) {
                console.error(error)
                toastConfig.login.error()
            } finally {
                setIsButtonDisabled(false)
            }
        },
        [navigate, signIn],
    )

    return {
        login,
        isButtonDisabled,
    } as const
}
