import { Loader2 } from 'lucide-react'
import { memo } from 'react'
import { PropsWithoutChildren } from 'types/rfc.type'

interface FullPageLoaderProps {}
export const FullPageLoader: React.FC<PropsWithoutChildren<FullPageLoaderProps>> = memo(() => {
    return (
        <div className="bg-background animate-in fade-in-0 fixed left-0 top-0 flex h-screen w-screen items-center justify-center transition-all duration-300">
            <Loader2 className="repeat-infinite text-muted-foreground animate-spin" />
        </div>
    )
})
FullPageLoader.displayName = 'FullPageLoader'
