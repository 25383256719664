import { PieChart } from 'components/pie-chart'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import {
    MAX_FIRESTORE_DELETES,
    MAX_FIRESTORE_READS,
    MAX_FIRESTORE_WRITES,
    MAX_FUNCTIONS_INVOCATIONS,
    defaultIconProps,
} from 'config/constants.config'
import { Book, Braces, Eraser, Pencil } from 'lucide-react'
import { memo, useMemo } from 'react'
import { CounterType } from 'types/counter.type'
import { kFormatter } from 'utils/k-formatter'

const iconProps = {
    ...defaultIconProps,
    className: 'text-muted-foreground',
}
const defaultCardProps = {
    className: 'w-full',
}

interface DashboardChartsProps extends React.ComponentProps<'div'> {
    data: CounterType
}

export const DashboardCharts: React.FC<DashboardChartsProps> = memo(({ data, ...props }) => {
    const functionsInvocationsData = useMemo(
        () => [
            {
                name: 'Used',
                value: data.functions.invocations ?? 0,
            },
            {
                name: 'Available',
                value: MAX_FUNCTIONS_INVOCATIONS - (data.functions.invocations ?? 0),
            },
        ],
        [data.functions.invocations],
    )

    const firestoreReadsData = useMemo(
        () => [
            {
                name: 'Used',
                value: data.firestore.reads ?? 0,
            },
            {
                name: 'Available',
                value: MAX_FIRESTORE_READS - (data.firestore.reads ?? 0),
            },
        ],
        [data.firestore.reads],
    )

    const firestoreWritesData = useMemo(
        () => [
            {
                name: 'Used',
                value: data.firestore.writes ?? 0,
            },
            {
                name: 'Available',
                value: MAX_FIRESTORE_WRITES - (data.firestore.writes ?? 0),
            },
        ],
        [data.firestore.writes],
    )

    const firestoreDeletesData = useMemo(
        () => [
            {
                name: 'Used',
                value: data.firestore.deletes ?? 0,
            },
            {
                name: 'Available',
                value: MAX_FIRESTORE_DELETES - (data.firestore.deletes ?? 0),
            },
        ],
        [data.firestore.deletes],
    )

    return (
        <div {...props}>
            {/* functions */}
            <Card {...defaultCardProps}>
                <CardHeader>
                    <CardTitle className="flex justify-between">
                        Functions Invocations
                        <Braces {...iconProps} />
                    </CardTitle>
                    <CardDescription>Used this month - {kFormatter(data.functions.invocations) ?? 'Unknown'}</CardDescription>
                </CardHeader>
                <CardContent>
                    <PieChart data={functionsInvocationsData} height={200} />
                </CardContent>
            </Card>

            {/* reads */}
            <Card {...defaultCardProps}>
                <CardHeader>
                    <CardTitle className="flex justify-between">
                        Firestore Reads
                        <Book {...iconProps} />
                    </CardTitle>
                    <CardDescription>Used today - {kFormatter(data.firestore.reads) ?? 'Unknown'}</CardDescription>
                </CardHeader>
                <CardContent>
                    <PieChart data={firestoreReadsData} height={200} />
                </CardContent>
            </Card>

            {/* writes */}
            <Card {...defaultCardProps}>
                <CardHeader>
                    <CardTitle className="flex justify-between">
                        Firestore Writes
                        <Pencil {...iconProps} />
                    </CardTitle>
                    <CardDescription>Used today - {kFormatter(data.firestore.writes) ?? 'Unknown'}</CardDescription>
                </CardHeader>
                <CardContent>
                    <PieChart data={firestoreWritesData} height={200} />
                </CardContent>
            </Card>

            {/* deletes */}
            <Card {...defaultCardProps}>
                <CardHeader>
                    <CardTitle className="flex justify-between">
                        Firestore Deletes
                        <Eraser {...iconProps} />
                    </CardTitle>
                    <CardDescription>Used today - {kFormatter(data.firestore.deletes) ?? 'Unknown'}</CardDescription>
                </CardHeader>
                <CardContent>
                    <PieChart data={firestoreDeletesData} height={200} />
                </CardContent>
            </Card>
        </div>
    )
})
DashboardCharts.displayName = 'DashboardCharts'
