import { Table } from '@tanstack/react-table'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
} from 'components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { Separator } from 'components/ui/separator'
import { Check, PlusCircle } from 'lucide-react'
import { capitalize } from 'utils/capitalize'
import { cn } from 'utils/cn'

interface DataTableFacetedFilter<TData, TValue> {
    table?: Table<TData>
    column: string
    options: {
        data: string
        icon?: React.ComponentType<{ className?: string }>
    }[]
}

/**
 * @description Add | filterFn: 'arrIncludes' | to column definition to enable this filter
 */
export const DataTableFacetedFilter = <TData, TValue>({ table, column, options }: DataTableFacetedFilter<TData, TValue>) => {
    if (!table) {
        return null
    }

    const tableColumn = table.getColumn(column)
    const facets = tableColumn?.getFacetedUniqueValues()
    const selectedValues = new Set(tableColumn?.getFilterValue() as string[])

    const onOptionSelect = (option: (typeof options)[0], isSelected = false) => {
        return () => {
            if (isSelected) {
                selectedValues.delete(option.data)
            } else {
                selectedValues.add(option.data)
            }
            const filterValues = [...selectedValues]
            tableColumn?.setFilterValue(filterValues.length ? filterValues : undefined)
        }
    }
    const onFiltersClear = () => {
        tableColumn?.setFilterValue(undefined)
    }

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button variant="outline" size="sm" className="border-dashed">
                    <PlusCircle className="mr-2 h-4 w-4" />
                    <span className="capitalize">{column}</span>
                    {selectedValues?.size > 0 && (
                        <>
                            <Separator orientation="vertical" className="mx-2 h-4" />
                            <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                                {selectedValues.size}
                            </Badge>
                            <div className="hidden space-x-1 lg:flex">
                                {selectedValues.size > 2 ? (
                                    <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                                        {selectedValues.size} selected
                                    </Badge>
                                ) : (
                                    options
                                        .filter(option => selectedValues.has(option.data))
                                        .map(option => (
                                            <Badge
                                                variant="secondary"
                                                key={option.data}
                                                className="rounded-sm px-1 font-normal capitalize"
                                            >
                                                {option.data}
                                            </Badge>
                                        ))
                                )}
                            </div>
                        </>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-0" align="start">
                <Command>
                    <CommandInput placeholder={capitalize(column)} />
                    <CommandList>
                        <CommandEmpty>No results found.</CommandEmpty>
                        <CommandGroup>
                            {options.map(option => {
                                const isSelected = selectedValues.has(option.data)
                                return (
                                    <CommandItem
                                        key={option.data}
                                        onSelect={onOptionSelect(option, isSelected)}
                                        className="cursor-pointer"
                                    >
                                        <div
                                            className={cn(
                                                'border-primary mr-2 flex h-4 w-4 items-center justify-center rounded-sm border',
                                                isSelected
                                                    ? 'bg-primary text-primary-foreground'
                                                    : 'opacity-50 [&_svg]:invisible',
                                            )}
                                        >
                                            <Check className={cn('h-4 w-4')} />
                                        </div>
                                        {option.icon && <option.icon className="text-muted-foreground mr-2 h-4 w-4" />}
                                        <span className="capitalize">{option.data}</span>
                                        {facets?.get(option.data) && (
                                            <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">
                                                {facets.get(option.data)}
                                            </span>
                                        )}
                                    </CommandItem>
                                )
                            })}
                        </CommandGroup>
                        {selectedValues.size > 0 && (
                            <>
                                <CommandSeparator />
                                <CommandGroup>
                                    <CommandItem onSelect={onFiltersClear} className="justify-center text-center">
                                        Clear filters
                                    </CommandItem>
                                </CommandGroup>
                            </>
                        )}
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}
