import { formatLocalDate } from 'config/dayjs.config'
import { memo } from 'react'

interface TableDateCellProps extends React.ComponentProps<'span'> {
    date: string
}
export const TableDateCell: React.FC<TableDateCellProps> = memo(({ date, ...props }) => {
    return <span {...props}>{formatLocalDate(date)}</span>
})
TableDateCell.displayName = 'TableDateCell'
