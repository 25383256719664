import { memo } from 'react'
import { PropsWithoutChildren } from 'types/rfc.type'

interface HeadingProps extends React.ComponentProps<'div'> {
    title: string
    description?: string
}
export const Heading: React.FC<PropsWithoutChildren<HeadingProps>> = memo(({ title, description, ...props }) => {
    return (
        <div {...props}>
            <h2 className="text-3xl font-bold tracking-tight">{title}</h2>
            {description && <p className="text-muted-foreground text-sm">{description}</p>}
        </div>
    )
})
Heading.displayName = 'Heading'
