import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { revalidateSeconds, rtkBaseQuery } from 'config/rtkQuery.config'
import { urlConfig } from 'config/url.config'
import { ProjectSchemaType } from 'types/schemas/project.schema'

interface ServerResponse {
    message: string
}

export const projectApi = createApi({
    reducerPath: 'projectApi',
    tagTypes: ['Project'],
    keepUnusedDataFor: revalidateSeconds,
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        getProjects: builder.query<ProjectSchemaType[], void>({
            query: () => urlConfig.api.project,
            providesTags: ['Project'],
        }),
        createProject: builder.mutation<ServerResponse, Pick<ProjectSchemaType, 'name' | 'url' | 'email' | 'interval'>>({
            query: project => ({
                url: urlConfig.api.project,
                method: 'POST',
                body: project,
            }),
            invalidatesTags: ['Project'],
        }),
        deleteProject: builder.mutation<ServerResponse, ProjectSchemaType['id']>({
            query: id => ({
                url: `${urlConfig.api.project}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Project'],
        }),
    }),
})

export const { useGetProjectsQuery, useCreateProjectMutation, useDeleteProjectMutation } = projectApi
