// eslint-disable-next-line @typescript-eslint/ban-types
type DisposableFn = (fn: Function) => Function
export const disposableFn: DisposableFn = fn => {
    let disposed = false
    return (...args: unknown[]) => {
        if (disposed) {
            return
        }
        disposed = true
        return fn(...args)
    }
}
