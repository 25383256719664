import { urlConfig } from 'config/url.config'
import { PropsWithChildren, memo } from 'react'
import { useIsAuthenticated } from 'react-auth-kit'
import { Navigate } from 'react-router-dom'

interface ThrowAuthProps {
    callbackPath?: string
}
export const ThrowAuth: React.FC<PropsWithChildren<ThrowAuthProps>> = memo(
    ({ callbackPath = urlConfig.pages.dashboard, children }) => {
        const isAuth = useIsAuthenticated()()

        return isAuth ? <Navigate to={callbackPath} replace /> : children
    },
)
ThrowAuth.displayName = 'ThrowAuth'
