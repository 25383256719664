import { memo } from 'react'
import { NODE_ENV_DEV, NODE_ENV_PROD } from 'utils/NODE_ENV'

interface TailwindIndicatorProps {}
export const TailwindIndicator: React.FC<TailwindIndicatorProps> = memo(() => {
    if (NODE_ENV_PROD || !NODE_ENV_DEV) return null

    return (
        <div className="fixed bottom-1 left-1 z-50 flex h-6 w-6 items-center justify-center rounded-full bg-slate-900 p-3 font-sans text-xs text-slate-50 transition-all duration-300">
            <div className="block sm:hidden">xs</div>
            <div className="hidden sm:block md:hidden">sm</div>
            <div className="hidden md:block lg:hidden">md</div>
            <div className="hidden lg:block xl:hidden">lg</div>
            <div className="hidden xl:block 2xl:hidden">xl</div>
            <div className="hidden 2xl:block">2xl</div>
        </div>
    )
})
TailwindIndicator.displayName = 'TailwindIndicator'
