import { Icons } from 'components/icons'
import { Button } from 'components/ui/button'
import { firebaseAuth, firebaseProvider } from 'config/firebase.config'
import { AdditionalUserInfo, User, getAdditionalUserInfo, signInWithPopup } from 'firebase/auth'
import { memo, useCallback } from 'react'

export type OnSignInWithGoogle = (
    user: { id?: string; name?: string; email?: string; image?: string },
    other?: {
        userInfo: User
        additionalUserInfo?: AdditionalUserInfo['profile']
    },
) => void

interface SignInWithGoogleProps extends Omit<React.ComponentProps<typeof Button>, 'onClick'> {
    onClick?: OnSignInWithGoogle
    contentClassName?: string
}

export const SignInWithGoogle: React.FC<SignInWithGoogleProps> = memo(
    ({ onClick, className, contentClassName, children, ...props }) => {
        const onSignIn = useCallback<() => Promise<void>>(async () => {
            try {
                const result = await signInWithPopup(firebaseAuth, firebaseProvider)
                const additionalResult = getAdditionalUserInfo(result)

                const userInfo = result.user
                const additionalUserInfo = additionalResult?.profile

                onClick?.(
                    {
                        id: userInfo?.uid ?? undefined,
                        name: userInfo?.displayName ?? undefined,
                        email: userInfo?.email ?? undefined,
                        image: userInfo?.photoURL ?? undefined,
                    },
                    {
                        userInfo,
                        additionalUserInfo,
                    },
                )
            } catch (error) {
                console.error(error)
            }
        }, [onClick])

        // return (
        //     <button
        //         onClick={signInWithGoogle}
        //         {...props}
        //         className={cn(
        //             'group m-0 flex h-12 items-center rounded border border-[#4285f4] p-0 transition-all duration-300 hover:border-[#3873d0] disabled:pointer-events-none disabled:opacity-50 dark:border-blue-950 dark:hover:border-blue-900',
        //             className,
        //         )}
        //     >
        //         <img
        //             src="/assets/google-logo.svg"
        //             alt="google logo"
        //             width={46}
        //             height={46}
        //             className="flex-1 rounded-lg transition-all duration-300"
        //         />
        //         <p
        //             className={cn(
        //                 'm-0 flex h-full flex-[5] cursor-pointer items-center justify-center bg-[#4285f4] px-5 text-sm font-semibold text-white transition-all duration-300 group-hover:bg-[#3873d0] dark:bg-blue-950 dark:group-hover:bg-blue-900',
        //                 contentClassName,
        //             )}
        //         >
        //             {!children && 'Sign in with Google'}
        //             {children}
        //         </p>
        //     </button>
        // )

        return (
            <Button variant="outline" onClick={onSignIn} {...props}>
                <Icons.google className="mr-2 h-4 w-4" />
                Google
            </Button>
        )
    },
)
SignInWithGoogle.displayName = 'SignInWithGoogle'
