import { CurrentTheme } from 'components/hoc/current-theme'
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import { Button } from 'components/ui/button'
import { InlineCode } from 'components/ui/code'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'components/ui/dropdown-menu'
import { commandMenuShortcut, defaultIconProps, themes, themesIcons } from 'config/constants.config'
import { urlConfig } from 'config/url.config'
import { Activity, LogIn, LogOut } from 'lucide-react'
import { CreateProjectSheet } from 'pages/create-project/create-project-sheet'
import { memo, useCallback } from 'react'
import { useAuthUser, useSignOut } from 'react-auth-kit'
import { Link, useNavigate } from 'react-router-dom'
import { setIsCommandOpenAction } from 'redux/slices/command.slice'
import { setThemeAction } from 'redux/slices/theme.slice'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { PropsWithoutChildren } from 'types/rfc.type'
import { ThemeType } from 'types/theme.type'
import { getFallbackLetters } from 'utils/get-fallback-letters'

interface NavbarProps {}
export const Navbar: React.FC<PropsWithoutChildren<NavbarProps>> = memo(() => {
    const isAuth = useAuthUser()()
    const auth = useAuthUser()
    const signOut = useSignOut()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { theme } = useAppSelector(state => state.theme)

    const onCommandOpen = useCallback<() => void>(() => {
        dispatch(setIsCommandOpenAction(true))
    }, [dispatch])

    const onLogOut = useCallback<() => void>(() => {
        signOut()
    }, [signOut])

    const onLogIn = useCallback<() => void>(() => {
        navigate('/login')
    }, [navigate])

    const onThemeChange = useCallback(
        (newTheme: ThemeType) => {
            return () => {
                dispatch(setThemeAction(newTheme))
            }
        },
        [dispatch],
    )

    return (
        <div className="bg-background/90 fixed z-50 w-full whitespace-nowrap border-b backdrop-blur-sm">
            <div className="container flex w-full items-center justify-between bg-none py-3">
                {/* logo */}
                <div className="flex items-center gap-2">
                    <Activity />
                    <Link to={urlConfig.pages.dashboard} className="text-lg font-bold">
                        Uptime Monitor
                    </Link>
                </div>
                {/* buttons */}
                <nav className="flex items-center gap-2">
                    {/* command */}
                    <Button
                        variant="none"
                        onClick={onCommandOpen}
                        className="border-input bg-background ring-offset-background text-muted-foreground focus-visible:ring-ring flex h-9 w-48 items-center justify-between rounded-md border px-3 text-sm font-normal transition-all duration-300 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                    >
                        Search...
                        <InlineCode className="text-xs">{commandMenuShortcut}</InlineCode>
                    </Button>
                    {isAuth && (
                        <>
                            {/* dashboard */}
                            <Button asChild variant="ghost" size="sm">
                                <Link to={urlConfig.pages.dashboard}>Dashboard</Link>
                            </Button>
                            {/* projects logs */}
                            <Button asChild variant="ghost" size="sm">
                                <Link to={urlConfig.pages.projectsLogs}>Projects Logs</Link>
                            </Button>
                            {/* app logs */}
                            <Button asChild variant="ghost" size="sm">
                                <Link to={urlConfig.pages.appLogs}>App Logs</Link>
                            </Button>
                            {/* create project */}
                            <Button variant="ghost" size="sm" asChild>
                                <CreateProjectSheet>Create Project</CreateProjectSheet>
                            </Button>
                        </>
                    )}
                    {/* theme */}
                    <DropdownMenu>
                        <Button variant="ghost" size="icon-sm" asChild>
                            <DropdownMenuTrigger>{theme === 'light' ? themesIcons.light : themesIcons.dark}</DropdownMenuTrigger>
                        </Button>
                        <DropdownMenuContent>
                            {themes.map(theme => (
                                <DropdownMenuItem key={theme.name} onClick={onThemeChange(theme.name)} className="flex gap-2">
                                    <span className="capitalize">{theme.name}</span>
                                    <CurrentTheme theme={theme.name}>
                                        <div className="bg-foreground h-1.5 w-1.5 rounded-full" />
                                    </CurrentTheme>
                                </DropdownMenuItem>
                            ))}
                        </DropdownMenuContent>
                    </DropdownMenu>
                    {/* avatar */}
                    <DropdownMenu>
                        <DropdownMenuTrigger>
                            <Avatar className="h-9 w-9">
                                <AvatarImage src={auth()?.image ?? ''} />
                                <AvatarFallback className="capitalize">
                                    {getFallbackLetters(auth()?.name ?? auth()?.email ?? 'U')}
                                </AvatarFallback>
                            </Avatar>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            {isAuth ? (
                                <DropdownMenuItem onClick={onLogOut} className="flex gap-2">
                                    <LogOut {...defaultIconProps} size={19} /> <span>Log out</span>
                                </DropdownMenuItem>
                            ) : (
                                <DropdownMenuItem onClick={onLogIn} className="flex gap-2">
                                    <LogIn {...defaultIconProps} size={19} /> <span>Log in</span>
                                </DropdownMenuItem>
                            )}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </nav>
            </div>
        </div>
    )
})
Navbar.displayName = 'Navbar'
