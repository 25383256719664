import { z } from 'zod'

export enum ProjectStatus {
    OK = 'ok',
    DOWN = 'down',
    REDIRECT = 'redirect',
    PENDING = 'pending',
}

export const ProjectSchema = z.object({
    id: z.string(),
    name: z.string(),
    url: z.string(),
    email: z.string().email(),
    status: z.nativeEnum(ProjectStatus),
    statusCode: z.number(),
    ping: z.number(),
    interval: z.number(),
    lastCheck: z.string(),
})
export type ProjectSchemaType = z.infer<typeof ProjectSchema>

export const CreateProjectSchema = z.object({
    name: ProjectSchema.shape.name,
    url: ProjectSchema.shape.url,
    email: ProjectSchema.shape.email,
    interval: z.string().refine(val => !Number.isNaN(parseInt(val, 10)), {
        message: 'Expected number, received string',
    }),
})
export type CreateProjectSchemaType = z.infer<typeof CreateProjectSchema>
