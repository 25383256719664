import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface CreateProjectSliceState {
    isOpen: boolean
}

const initialState: CreateProjectSliceState = {
    isOpen: false,
}

export const createProjectSlice = createSlice({
    name: 'createProject',
    initialState,
    reducers: {
        setIsCreateProjectOpenAction: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload
        },
    },
})

export const { setIsCreateProjectOpenAction } = createProjectSlice.actions
