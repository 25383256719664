import { InlineCode } from 'components/ui/code'
import { toast } from 'components/ui/use-toast'
import { commandMenuShortcut } from 'config/constants.config'

export const toastConfig = {
    refetch: {
        success: () =>
            toast({
                title: 'Refetched',
                description: 'Now you can see the latest data',
            }),
        error: () =>
            toast({
                title: 'Failed to refetch',
                description: 'Please try again later',
                variant: 'destructive',
            }),
    },

    //

    login: {
        success: (name: string) =>
            toast({
                title: 'Login successful',
                description: (
                    <>
                        Welcome back, <InlineCode>{name}</InlineCode>
                    </>
                ),
            }),
        error: () =>
            toast({
                title: 'Failed to login',
                description: 'Please try again later',
                variant: 'destructive',
            }),
        successWithEmail: (email: string) =>
            toast({
                title: 'First step is done!',
                description: (
                    <>
                        Check your email <InlineCode>{email}</InlineCode> to confirm it. If you don't see the letter, check the
                        spam folder
                    </>
                ),
            }),
    },

    //

    project: {
        create: {
            success: (name: string) =>
                toast({
                    title: 'Project created',
                    description: (
                        <>
                            Project <InlineCode>{name}</InlineCode> created successfully
                        </>
                    ),
                }),
            error: (name: string) =>
                toast({
                    title: 'Failed to create project',
                    description: (
                        <>
                            Error occured while creating project <InlineCode>{name}</InlineCode> Please try again later
                        </>
                    ),
                    variant: 'destructive',
                }),
        },
        delete: {
            success: (name: string) =>
                toast({
                    title: 'Project deleted',
                    description: (
                        <>
                            Project <InlineCode>{name}</InlineCode> deleted successfully
                        </>
                    ),
                }),
            error: (name: string) =>
                toast({
                    title: 'Failed to delete project',
                    description: (
                        <>
                            Error occured while updating <InlineCode>{name}</InlineCode>. Please try again later
                        </>
                    ),
                    variant: 'destructive',
                }),
        },
    },

    //

    logs: {
        delete: {
            success: () =>
                toast({
                    title: 'Logs deleted',
                    description: 'All logs deleted successfully',
                }),
            error: () =>
                toast({
                    title: 'Failed to delete logs',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
    },

    //

    commandMenuTip: {
        success: () =>
            toast({
                title: 'Cool Tip',
                description: (
                    <span>
                        Press <InlineCode>{commandMenuShortcut}</InlineCode> to open the command menu
                    </span>
                ),
            }),
    },
}
