import { ConfirmDialog } from 'components/confirm-dialog'
import { FullPageLoader } from 'components/full-page-loader'
import { Heading } from 'components/heading'
import { Button } from 'components/ui/button'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import { toastConfig } from 'config/toasts.config'
import { Trash2 } from 'lucide-react'
import { AppLogsCard } from 'pages/app-logs/components/app-logs-card'
import { memo, useCallback } from 'react'
import {
    useDeleteAppLogsMutation,
    useGetAuthLAppLogsQuery,
    useGetDebugAppLogsQuery,
    useGetErrorAppLogsQuery,
    useGetInfoLAppLogsQuery,
    useGetWarnLAppLogsQuery,
} from 'redux/api/app-logs-api'

interface AppLogsPageProps {}
export const AppLogsPage: React.FC<AppLogsPageProps> = memo(() => {
    const { data: authLogs, isLoading: authLogsLoading } = useGetAuthLAppLogsQuery()
    const { data: infoLogs, isLoading: infoLogsLoading } = useGetInfoLAppLogsQuery()
    const { data: debugLogs, isLoading: debugLogsLoading } = useGetDebugAppLogsQuery()
    const { data: warnLogs, isLoading: warnLogsLoading } = useGetWarnLAppLogsQuery()
    const { data: errorLogs, isLoading: errorLogsLoading } = useGetErrorAppLogsQuery()
    const [deleteLogs, deleteLogsRes] = useDeleteAppLogsMutation()

    const onRemove = useCallback(async () => {
        try {
            const res = await deleteLogs().unwrap()
            if (deleteLogsRes.isError) {
                console.error(deleteLogsRes.error)
                throw new Error()
            }
            toastConfig.logs.delete.success()
        } catch (error) {
            console.error(error)
            toastConfig.logs.delete.error()
        }
    }, [deleteLogs, deleteLogsRes.error, deleteLogsRes.isError])

    if (authLogsLoading || infoLogsLoading || debugLogsLoading || warnLogsLoading || errorLogsLoading) {
        return <FullPageLoader />
    }

    return (
        <div className="animate-in fade-in-0 bg-background container flex min-h-screen flex-col space-y-5 pt-24 transition-all duration-300">
            <div className="flex w-full items-center justify-between">
                <Heading title="Logs" description="See what's happening on the server" />
                <ConfirmDialog
                    title="Are you sure?"
                    description="Do you really want to delete all logs?"
                    onClick={onRemove}
                    asChild
                >
                    <Button variant="outline" size="icon">
                        <Trash2 />
                    </Button>
                </ConfirmDialog>
            </div>

            <Tabs defaultValue="Auth" className="w-full">
                <TabsList className="grid w-full grid-cols-5">
                    <TabsTrigger value="Auth">Auth</TabsTrigger>
                    <TabsTrigger value="Info">Info</TabsTrigger>
                    <TabsTrigger value="Debug">Debug</TabsTrigger>
                    <TabsTrigger value="Warn">Warn</TabsTrigger>
                    <TabsTrigger value="Error">Error</TabsTrigger>
                </TabsList>
                <TabsContent value="Auth">
                    <AppLogsCard title="Auth logs">{authLogs}</AppLogsCard>
                </TabsContent>
                <TabsContent value="Info">
                    <AppLogsCard title="Info logs">{infoLogs}</AppLogsCard>
                </TabsContent>
                <TabsContent value="Debug">
                    <AppLogsCard title="Debug logs">{debugLogs}</AppLogsCard>
                </TabsContent>
                <TabsContent value="Warn">
                    <AppLogsCard title="Warn logs">{warnLogs}</AppLogsCard>
                </TabsContent>
                <TabsContent value="Error">
                    <AppLogsCard title="Error logs">{errorLogs}</AppLogsCard>
                </TabsContent>
            </Tabs>
        </div>
    )
})
AppLogsPage.displayName = 'LogsCard'
