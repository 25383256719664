export const urlConfig = {
    pages: {
        dashboard: '/',
        login: '/login',
        projectsLogs: '/projects-logs',
        appLogs: '/app-logs',
    },
    api: {
        login: '/login',
        counter: '/counter',
        project: '/project',
        revalidate: '/revalidate',
        projectLogs: '/project-log',
        appLogs: '/app-log',
    },
}
