import { PropsWithChildren, memo } from 'react'
import { useAppSelector } from 'redux/store'
import { ThemeType } from 'types/theme.type'

interface CurrentThemeProps {
    theme: ThemeType
}
/**
 * @description render children only in specific theme
 */
export const CurrentTheme: React.FC<PropsWithChildren<CurrentThemeProps>> = memo(({ theme, children }) => {
    const { theme: currentTheme } = useAppSelector(state => state.theme)

    return currentTheme === theme ? children : null
})
CurrentTheme.displayName = 'CurrentTheme'
