import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { revalidateSeconds, rtkBaseQuery } from 'config/rtkQuery.config'
import { urlConfig } from 'config/url.config'
import { CounterType } from 'types/counter.type'

export const counterApi = createApi({
    reducerPath: 'counterApi',
    tagTypes: ['Counter'],
    keepUnusedDataFor: revalidateSeconds,
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        getCounter: builder.query<CounterType, void>({
            query: () => urlConfig.api.counter,
            providesTags: ['Counter'],
        }),
    }),
})

export const { useGetCounterQuery } = counterApi
