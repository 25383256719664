import { Button } from 'components/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'components/ui/dropdown-menu'
import { defaultIconProps } from 'config/constants.config'
import { toastConfig } from 'config/toasts.config'
import { MoreHorizontal } from 'lucide-react'
import { memo, useCallback } from 'react'
import { useDeleteProjectMutation } from 'redux/api/project-api'
import { PropsWithoutChildren } from 'types/rfc.type'
import { ProjectSchemaType } from 'types/schemas/project.schema'

interface DashboardRowActionsCellProps {
    id: ProjectSchemaType['id']
    name: ProjectSchemaType['name']
}
export const DashboardRowActionsCell: React.FC<PropsWithoutChildren<DashboardRowActionsCellProps>> = memo(({ id, name }) => {
    const [deleteProject, deleteProjectRes] = useDeleteProjectMutation()

    const onDelete = useCallback<() => void>(() => {
        try {
            deleteProject(id)
            if (deleteProjectRes.isError) {
                console.error(deleteProjectRes.error)
                throw new Error()
            }
            toastConfig.project.delete.success(name)
        } catch (error) {
            toastConfig.project.delete.error(name)
        }
    }, [deleteProject, deleteProjectRes.error, deleteProjectRes.isError, id, name])

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon" className="data-[state=open]:bg-muted flex h-8 w-8 p-0">
                    <MoreHorizontal {...defaultIconProps} className="h-4 w-4" />
                    <span className="sr-only">Open more</span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[160px]">
                <DropdownMenuItem onSelect={onDelete} className="w-full cursor-pointer">
                    Delete
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
})
DashboardRowActionsCell.displayName = 'DashboardRowActionsCell'
